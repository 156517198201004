// config/UI tabs
export const TAB_UI_TEL = 'UI_TEL';
export const TAB_UI_CLO = 'UI_CLO';
export const TAB_UI_LOAD = 'UI_LOAD';
export const TAB_UI_GENERAL = 'UI_GENERAL';
export const TAB_UI_LOAD_BOARDS = 'UI_LOAD_BOARDS';
export const TAB_UI_DRIVER_CARDS = 'UI_DRIVER_CARDS';
export const TAB_UI_DISPATCH_BOARD = 'UI_DISPATCH_BOARD';
export const TAB_UI_AVAILABLE_DRIVERS = 'UI_AVAILABLE_DRIVERS';

// config/invoices tabs
export const TAB_INVOICES_GENERAL = 'INVOICES_GENERAL';
export const TAB_INVOICES_GL_CODES = 'INVOICES_GL_CODES';
export const TAB_INVOICES_QUICK_BOOKS = 'INVOICES_QUICK_BOOKS';
export const TAB_INVOICES_SAGE_INTACCT = 'INVOICES_SAGE_INTACCT';
export const TAB_INVOICES_FLEET_INVOICE = 'INVOICES_FLEET_INVOICE';
export const TAB_INVOICES_EXPENSE_TYPES = 'INVOICES_EXPENSE_TYPES';
export const TAB_INVOICES_BUSINESS_CENTAL = 'INVOICES_BUSINESS_CENTAL';
export const TAB_INVOICES_CARRIER_INVOICE = 'INVOICES_CARRIER_INVOICE';
export const TAB_INVOICES_CUSTOMER_INVOICE = 'INVOICES_CUSTOMER_INVOICE';

// config/carrier tabs
export const TAB_CARRIER_GENERAL = 'CARRIER_GENERAL';
export const TAB_CARRIER_INSURANCE = 'CARRIER_INSURANCE';
export const TAB_CARRIER_DROPDOWNS = 'CARRIER_DROPDOWNS';
export const TAB_CARRIER_ACCEPT_RATE = 'CARRIER_ACCEPT_RATE';
export const TAB_CARRIER_SCORE_CARDS = 'CARRIER_SCORE_CARDS';
export const TAB_CARRIER_STATUS_CHECK = 'CARRIER_STATUS_CHECK';
export const TAB_CARRIER_QUOTE_PROCESS = 'CARRIER_QUOTE_PROCESS';
export const TAB_CARRIER_DISPATCH_PROCESS = 'CARRIER_DISPATCH_PROCESS';
export const TAB_CARRIER_ONBOARDING_PACKAGE = 'CARRIER_ONBOARDING_PACKAGE';

// config/service vendor tabs
export const TAB_SERVICE_VENDOR_GENERAL = 'SERVICE_VENDOR_GENERAL';

// config/general tabs
export const TAB_GENERAL_ROUTE = 'GENERAL_ROUTE';
export const TAB_GENERAL_BRANCH = 'GENERAL_BRANCH';
export const TAB_GENERAL_SERVICES = 'GENERAL_SERVICES';
export const TAB_GENERAL_EQUIPMENTS = 'GENERAL_EQUIPMENTS';
export const TAB_GENERAL_ACCESSORIALS = 'GENERAL_ACCESSORIALS';
export const TAB_GENERAL_SERVICE_TYPE = 'GENERAL_SERVICE_TYPE';
export const TAB_GENERAL_CALCULATIONS = 'GENERAL_CALCULATIONS';
export const TAB_GENERAL_LOAD_CUSTOM_STATUS = 'GENERAL_LOAD_CUSTOM_STATUS';
export const TAB_GENERAL_MODE_OF_TRANSPORTATION = 'GENERAL_MODE_OF_TRANSPORTATION';

// config/clo tabs
export const TAB_CLO_ITEM = 'CLO_ITEM';
export const TAB_CLO_RATE = 'CLO_RATE';
export const TAB_CLO_QUOTE = 'CLO_QUOTE';
export const TAB_CLO_GENERAL = 'CLO_GENERAL';
export const TAB_CLO_BILL_TO = 'CLO_BILL_TO';
export const TAB_CLO_ORDER_TYPE = 'CLO_ORDER_TYPE';
export const TAB_CLO_CANCEL_ORDER = 'CLO_CANCEL_ORDER';

// config/tel tabs
export const TAB_TEL_RATE = 'TEL_RATE';
export const TAB_TEL_GENERAL = 'TEL_GENERAL';

// config/fleet general tabs
export const TAB_FLEET_ISSUE = 'FLEET_ISSUE';
export const TAB_FLEET_GENERAL = 'FLEET_GENERAL';
export const TAB_FLEET_MAINTENANCE = 'FLEET_MAINTENANCE';

// config/fleet driver tabs
export const TAB_FLEET_DRIVER_SAFETY = 'FLEET_DRIVER_SAFETY';
export const TAB_FLEET_DRIVER_GENERAL = 'FLEET_DRIVER_GENERAL';
export const TAB_FLEET_DRIVER_TRACKING = 'FLEET_DRIVER_TRACKING';
export const TAB_FLEET_DRIVER_ONBOARDING_INTEGRATION = 'FLEET_DRIVER_ONBOARDING_INTEGRATION';

// config/fleet truck tabs
export const TAB_FLEET_TRUCK_IFTA = 'FLEET_TRUCK_IFTA';
export const TAB_FLEET_TRUCK_GENERAL = 'FLEET_TRUCK_GENERAL';
export const TAB_FLEET_TRUCK_MAINTENANCE = 'FLEET_TRUCK_MAINTENANCE';

// config/fleet trailer tabs
export const TAB_FLEET_TRAILER_GENERAL = 'FLEET_TRAILER_GENERAL';
export const TAB_FLEET_TRAILER_MAINTENANCE = 'FLEET_TRAILER_MAINTENANCE';

// config/communication tabs
export const TAB_COMMUNICATION_MAILS = 'COMMUNICATION_MAILS';
export const TAB_COMMUNICATION_GENERAL = 'COMMUNICATION_GENERAL';
export const TAB_COMMUNICATION_DOCUMENTS = 'COMMUNICATION_DOCUMENTS';
export const TAB_COMMUNICATION_NOTIFICATIONS = 'COMMUNICATION_NOTIFICATIONS';
export const TAB_COMMUNICATION_STATUS_MESSAGES = 'COMMUNICATION_STATUS_MESSAGES';

// config/integration tabs
export const TAB_INTEGRATION_WMS = 'INTEGRATION_WMS';
export const TAB_INTEGRATION_BOSCH = 'INTEGRATION_BOSCH';
export const TAB_INTEGRATION_VEHICLE = 'INTEGRATION_VEHICLE';
export const TAB_INTEGRATION_TRAILER = 'INTEGRATION_TRAILER';
export const TAB_INTEGRATION_CUSTOMER = 'INTEGRATION_CUSTOMER';
export const TAB_INTEGRATION_FINANCIAL = 'INTEGRATION_FINANCIAL';
export const TAB_INTEGRATION_ANALYTICS = 'INTEGRATION_ANALYTICS';
export const TAB_INTEGRATION_DOCUMENTS = 'INTEGRATION_DOCUMENTS';
export const TAB_INTEGRATION_ACCOUNTING = 'INTEGRATION_ACCOUNTING';
export const TAB_INTEGRATION_LOAD_BOARDS = 'INTEGRATION_LOAD_BOARDS';
export const TAB_INTEGRATION_FLEET_VENDOR = 'INTEGRATION_FLEET_VENDOR';
export const TAB_INTEGRATION_CROSS_BORDER = 'INTEGRATION_CROSS_BORDER';
export const TAB_INTEGRATION_CARRIER_VENDOR = 'INTEGRATION_CARRIER_VENDOR';
export const TAB_INTEGRATION_STATUS_CODE_MAPPING = 'INTEGRATION_STATUS_CODE_MAPPING';

// config/mobile application tabs
export const TAB_MOBILE_APPLICATION_DRIVER = 'MOBILE_APPLICATION_DRIVER';
export const TAB_MOBILE_APPLICATION_CARRIER = 'MOBILE_APPLICATION_CARRIER';
